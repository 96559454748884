import * as React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import Boxed from "../components/Boxed";
import TextContent from "../components/TextContent";
import LegalWarningTR from "content/LegalWarningTR";

const LegalWarningTRPage = () => {
  return <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Yasal Uyarı | Paramla</title>
    </Helmet>
    <Boxed>
      <TextContent title="Yasal Uyarı">
        <LegalWarningTR />
      </TextContent>
    </Boxed>
  </Layout>
}

export default LegalWarningTRPage;